<script setup lang="ts">
import { useSlots, type Slots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PrimeModalLayout } from '@primeinsightsgroupllc-modals/prime-modals';

const slots: Slots = useSlots();
</script>
<template>
	<PrimeModalLayout hide-bottom-border class="modal-common-content">
		<template v-if="slots.header" #title>
			<PrimeText tag="h2" weight="600">
				<slot name="header" />
			</PrimeText>
		</template>
		<template #content>
			<span v-if="slots.icon" class="content-icon scale-in">
				<slot name="icon" />
			</span>
			<PrimeText v-if="slots.title" tag="p" weight="500" class="content-title">
				<slot name="title" />
			</PrimeText>
			<PrimeText
				v-if="slots.description"
				tag="p"
				size="sm"
				color="grey-700"
				weight="500"
				class="content-description"
			>
				<slot name="description" />
			</PrimeText>
			<PrimeText
				v-if="slots.message"
				tag="p"
				size="sm"
				color="grey-600"
				weight="500"
				class="content-message"
			>
				<slot name="message" />
			</PrimeText>
			<div v-if="slots.inputs" class="content-inputs">
				<slot name="inputs" />
			</div>

			<slot v-if="slots.default" name="default" />
		</template>
		<template v-if="slots.actions || slots.raw" #bottom>
			<div
				v-if="slots.actions"
				:class="{
					'inputs-actions': slots.inputs,
				}"
				class="bottom-wrapper"
			>
				<slot name="actions" />
			</div>

			<div v-if="slots.raw" class="bottom-wrapper">
				<slot name="raw" />
			</div>
		</template>
	</PrimeModalLayout>
</template>

<style lang="scss">
@use '@/styles/mixins.scss' as *;

.p-modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.content-icon {
	flex: none;
	margin-bottom: 1.375rem;
}

.content-icon img {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: auto;
}

.content-icon,
.content-icon svg {
	width: 3.375rem;
	height: 3.375rem;

	@include breakpoint(mobile) {
		width: 5.875rem;
		height: 5.875rem;
	}
}

.content-title {
	margin-bottom: 0.25rem;
	max-width: 85%;

	@include breakpoint(mobile) {
		max-width: 75%;
	}
}

.content-description {
	border-bottom: 1px solid var(--grey-200);
	margin-bottom: 0.75rem;
	padding-bottom: 0.75rem;
	width: 100%;
}

.content-message {
	max-width: 85%;

	@include breakpoint(mobile) {
		max-width: 70%;
	}
}

.content-inputs {
	width: 100%;
	& .p-input-wrapper:not(:first-of-type) {
		margin-top: 0.75rem;
	}
}
</style>
