<script setup lang="ts">
import { useSlots, type Slots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ImportantNote from '@/components/ImportantNote.vue';
import { PrimeModalLayout } from '@primeinsightsgroupllc-modals/prime-modals';

const slots: Slots = useSlots();
</script>
<template>
	<PrimeModalLayout
		hide-bottom-border
		class="modal-common-content-v2"
		:class="{ 'add-padding': !slots.actions }"
	>
		<template v-if="slots.header" #title>
			<PrimeText
				v-if="slots.header"
				tag="h2"
				weight="600"
				class="content-header"
				transform="capitalize"
			>
				<slot name="header" />
			</PrimeText>
		</template>

		<template #content>
			<span v-if="slots.icon" class="icon">
				<slot name="icon" />
			</span>

			<div v-if="slots.title" class="title">
				<PrimeText
					v-if="slots.header"
					tag="h2"
					weight="600"
					size="lg"
					color="dark"
				>
					<slot name="title" />
				</PrimeText>
			</div>

			<div v-if="slots.box" class="box">
				<slot name="box" />
			</div>

			<PrimeText
				v-if="slots.message"
				tag="p"
				size="sm"
				color="grey-700"
				weight="500"
				class="content-message"
			>
				<slot name="message" />
			</PrimeText>

			<div v-if="slots.note" class="note">
				<ImportantNote><slot name="note" /></ImportantNote>
			</div>

			<div v-if="slots.custom" class="custom">
				<slot name="custom" />
			</div>
		</template>

		<template v-if="slots.actions" #bottom>
			<div
				class="content-actions"
				:class="{
					'inputs-actions': slots.inputs,
				}"
			>
				<slot name="actions" />
			</div>
		</template>
	</PrimeModalLayout>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.modal-common-content-v2 {
	.title {
		margin-top: 1.375rem;
	}

	.icon {
		img,
		svg {
			display: block;
			max-width: 9.375rem;
			height: auto;
		}
		@include breakpoint(mobile) {
			img,
			svg {
				max-width: 12.5rem;
			}
		}
	}

	.box {
		width: 100%;
		padding: 0.625rem;
		background: var(--base-primary-09);
		border-radius: 0.5rem;
		margin-top: 1.375rem;

		& > * {
			margin-top: 0.25rem;
		}
		& > *:first-child {
			margin-top: 0;
		}
	}

	.content-message {
		margin-top: 0.75rem;
		text-align: center;
		white-space: pre-line;
	}

	.content-actions {
		display: flex;
		flex-direction: column;
		width: 100%;

		& > * {
			margin-bottom: 0.75rem;
		}
		& > *:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(mobile) {
			flex-direction: row;

			& > * {
				margin-right: 0.75rem;
				margin-bottom: 0;
			}
			& > *:last-child {
				margin-right: 0;
			}
		}
	}

	.note {
		width: 100%;
	}

	.note-content-wrapper {
		margin: 0;
	}

	.custom {
		width: calc(100% + 0.5rem);
		margin-top: 1.25rem;
		overflow: auto;
		padding-right: 0.5rem;
		margin-right: -0.5rem;
	}
}

:deep(.p-modal-content) {
	.p-without-border-bottom.modal-common-content-v2.add-padding & {
		padding-bottom: 1.375rem !important;
	}
}
</style>
