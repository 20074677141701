import { computed, inject, type InjectionKey, provide, ref, watch } from 'vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { getLanguage } from '@/utils/helpers';
import { storeToRefs } from 'pinia';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useUserStore } from '@/stores/user';
import { appColorMode } from '@/utils/local-storage';
import { ColorModes } from '@/enums';

export const primeChatInjection: InjectionKey<
	ReturnType<typeof providePrimeChat>
> = Symbol();

export const usePrimeChatIntegration = () => inject(primeChatInjection)!;

export const providePrimeChat = () => {
	const { platform } = useMobileApp();
	const { localizationCookie } = useAppCookies();
	const userStore = useUserStore();

	const { userId, userHash, source } = storeToRefs(userStore);
	const language = ref(getLanguage(localizationCookie));
	const appId = ref(import.meta.env.VITE_PS_APPID);

	const integrationChatLoading = ref(true);

	const isSystemDarkMode = computed(
		() =>
			window.matchMedia &&
			window.matchMedia('(prefers-color-scheme: dark)').matches
	);

	const paletteMapping = {
		[ColorModes.INIT]: false,
		[ColorModes.LIGHT]: false,
		[ColorModes.DARK]: true,
		[ColorModes.SYSTEM]: isSystemDarkMode.value,
	};

	const isDarkMode = computed(
		() => paletteMapping[appColorMode.value as ColorModes]
	);

	watch(isDarkMode, () => {
		initPrimeChat();
	});

	const initPrimeChat = () => {
		window.PrimeChat('init', {
			appId: appId.value,
			userId: userId.value || '',
			language: language.value || 'en',
			sHash: userHash.value || '',
			source: source.value,
			platform: platform.value,
			darkMode: isDarkMode.value,
		});
	};

	const renderPrimeChat = (container: string): (() => void) =>
		onUserId(() => {
			integrationChatLoading.value = false;
			window.PrimeChat('render', {
				container,
				language: language.value || 'en',
			});
		});

	const onUserId = (cb: () => void) =>
		watch(
			userId,
			(userIdValue) => {
				if (userIdValue) {
					const interval = setInterval(() => {
						if (typeof window.PrimeChat === 'function') {
							clearInterval(interval);
							cb();
						}
					}, 100);
				}
			},
			{ immediate: true }
		);

	const unmountPrimeChat = () => {
		window.PrimeChat('unmount');
	};

	onUserId(initPrimeChat);

	const providedData = {
		renderPrimeChat,
		unmountPrimeChat,
		integrationChatLoading,
	};

	provide(primeChatInjection, providedData);

	return providedData;
};
