<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { BALANCE, POINTS } from '@/locales/constants';
import { transformCurrency, localizePoints } from '@/utils/helpers';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useI18n } from 'vue-i18n';
import { PiWalletDuotone } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeText,
	PrimeOverlayPanel,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import DailyStreak from '@/components/common/header/DailyStreak.vue';
import UserLevel from '@/components/common/header/UserLevel.vue';
import UserRating from '@/components/common/header/UserRating.vue';

const { t } = useI18n();
const { isTablet, isLaptop } = useScreenSize();
const { collectedCoins, currencyBalance, currency } =
	storeToRefs(useUserStore());

const userBalanceInfo = ref(false);
const userBalanceValue = ref(null);

const balanceText = computed(() => {
	return `${localizePoints(collectedCoins.value)} ${t(POINTS)}${
		!isTablet.value
			? ` ≈ ${transformCurrency(+currencyBalance.value!, currency.value, 2)}`
			: ''
	}`;
});

const toggleUserBalanceInfo = () => {
	if (!isTablet.value) return;
	userBalanceInfo.value = !userBalanceInfo.value;
};
</script>

<template>
	<div class="user-balance-wrapper">
		<div class="user-balance">
			<div class="user-balance-left">
				<div v-if="!isLaptop" class="user-balance-icon">
					<PiWalletDuotone />
				</div>

				<div
					ref="userBalanceValue"
					class="user-balance-value"
					@click="toggleUserBalanceInfo"
				>
					<PrimeText
						weight="500"
						:size="isTablet ? 'xs' : 'sm'"
						color="grey-600"
					>
						{{ $t(BALANCE) }}:
					</PrimeText>
					<PrimeText weight="600" :size="isLaptop ? 'xs' : 'base'">
						{{ balanceText }}
					</PrimeText>

					<PrimeOverlayPanel
						:reference-el="userBalanceValue"
						:is-open="userBalanceInfo"
						show-arrow
						align-x="bottom-start"
						@update="userBalanceInfo = false"
					>
						<div class="user-balance-info">
							<div>
								<PiWalletDuotone />
							</div>
							<div class="user-balance-info-content">
								<PrimeText tag="p" color="grey-600" size="xs">
									{{ $t(BALANCE) }}:
								</PrimeText>
								<PrimeText tag="p" weight="600">
									{{
										`${localizePoints(collectedCoins)} ${t(POINTS)}${` ≈ ${transformCurrency(+currencyBalance!, currency, 2)}`}`
									}}
								</PrimeText>
							</div>
						</div>
					</PrimeOverlayPanel>
				</div>
			</div>
			<div class="user-balance-right">
				<UserRating />
				<DailyStreak />
				<UserLevel />
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@use '@/styles/mixins.scss' as *;
.user-balance-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: calc(env(safe-area-inset-top) + 0.75rem)
		var(--app-content-horizontal-gap) 0;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	z-index: 49;
	transform: translateZ(0);
	-webkit-transform: translateZ(0);

	@include breakpoint(tablet) {
		left: 15rem;
		right: 15rem;
		width: calc(100% - 30rem);
		padding-top: 1.625rem;
	}
	@include breakpoint(laptop) {
		left: 18.75rem;
		right: 18.75rem;
		width: calc(100% - 37.5rem);
	}
}
.user-balance {
	width: 100%;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	box-shadow: var(--box-shadow);
	padding: 0.75rem;
	border-radius: 0.75rem;

	@include breakpoint(tablet) {
		padding: 1.5rem 0.75rem;
	}
	@include breakpoint(laptop) {
		padding: 1.5rem;
	}
}
.user-balance-left {
	display: flex;
	align-items: center;
}
.user-balance-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background: var(--grey-50);
	margin-right: 0.75rem;
}
.user-balance-value {
	display: flex;
	flex-direction: column;
}
.user-balance-right {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	@include breakpoint(tablet) {
		gap: 0.75rem;
	}
}
.user-balance-info {
	display: flex;
	gap: 0.375rem;
	min-width: '16.375rem';
	max-width: '100%';
}
.user-balance-info-content {
	display: flex;
	flex-direction: column;
	gap: 0.125rem;
}
</style>
